.dark-color-container{
    background: #3e4e9b;
    color: white;
    /* padding-left:10px; */
    padding-right: 0px;
    margin:0px;
  }

.mid-color-container{
    background: #7495c0;
    /* position:absolute; */
    padding-bottom: 20px;
    width: 165px;
    height:auto;
}
.light-color-container{
    background: #cedeec;
    /* width: 100%; */
    height: 100%;
    vertical-align: top;
    
  }

.horizontal-line{
    height: 1px;
    background-color: white;
    width: 80%;
    margin: 10px auto;
}
/* .dark-color-container table{
    width: 70%;
    border-collapse: collapse;
}

.dark-color-container td:nth-child(1){
    /* border-top: 1px solid white; */
    /* text-align: right;
    padding-top: 5px;
    padding-bottom: 5px;
} */ 
  /* Top Tab Feature */
.tab-container{
    background: #3e4e9b;
    color: white;
    padding: 10px;
    margin: 0px;
    border-radius: 8px;
}

.tab-buttons{
    display: flex;
    align-items: center;
    margin-bottom:10px;
    height: 30px;
}

.tab-buttons button{
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    font-size: 20px;
    color: white;
}

.tab-buttons-no-cursor{
    display: flex;
    align-items: center;
    margin-bottom:10px;
    height: 30px;
}
.tab-buttons-no-cursor button{
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    cursor: default;
    outline: none;
    font-size: 20px;
    color: white;
}
/* .tab-buttons button.active-tab{
    background-color:#2d3870;
    color: white;
} */
.tab-buttons .vertical-line{
    width: 1px;
    background-color: white;
    height: 80%;
    margin: 0 3px;
}
.full-width{
    width:100%;
    height: 100%;
    position:absolute;
}
.wide-float-right{
    float: right;
}
/* Side navigation */
.side-container{
    background: #3e4e9b;
    color: white;
    padding: 15px 10px;
    margin: 0px;
    /* border-radius: 8px; */
    font-size: 25px;
    font-family: "Arial Narrow", sans-serif;
    float: left;
    top:0;
    width: 125px;
}

.side-buttons{
    display: flex;
    align-items: center;
    margin-bottom:10px;
    height: 30px;
    font-size: 20px;
}

.side-buttons button{
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    font-size: 20px;
    color: white;
}
/* .side-buttons button.active-tab{
    background-color:#2d3870;
    color: white;
} */

.side-container h3{
    padding-bottom: 20px;
    color: white;
    padding-left: 5px;
}
