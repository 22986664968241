* {
  margin:0;
  padding: 0px;
  border: 0px;
  border-collapse:collapse;
}
body{
  width:100%;
  height: 100vh;

}
.full-space{
  width:100%;
  height: 100vh;
}

img{
  max-width:85%;
  height:auto;
}

p.suggest{
  text-align: center;
  color: #000040;
  font-family:Bookman,"URW Bookman L", serif;
  font-size: 40px;
  font-variant: small-caps;
  font-stretch: semi-condensed;
  padding-top: 5px;
}
p.lightTitle{
  color:#000071;
  font-size:30px;
  font-family:"Trebuchet MS", sans-serif;
}
p.light{
  color:#000071;
  font-size:20px;
  font-family:"Trebuchet MS", sans-serif;
  padding-right:20px;
  padding-left:20px;
}

div.topNav{
  background:#3e4e9b;
  overflow:hidden;
}
div.topNav a{
  color: white;
  text-align:center;
  padding: 5px;
  text-decoration: none;
  font-size: 14px;
}

div.topNav-right{
  float:right;
}

div.sideNav{
  float:left;
  background: #3e4e9b;
  margin-right: 20px;
  margin-top:0px;
  margin-left: 0px;
  padding:5px;
}

div.sideNav a{
  color:white;
  text-align: right;
  padding-top: 5px;
  padding-bottom:5px;
  padding-right:5px;
  text-decoration:none;
  font-size: 20px;
  font-family: "Arial Narrow", sans-serif;
}
div.sideNav td{
  padding-top:10px;
  padding-bottom:10px;
  text-align:right;
}
div.sideNav p{
  color:white;
  text-align:left;
  padding-top: 5px;
  padding-left: 5px;
  text-decoration: none;
  font-size: 25px;
  font-family: "Arial Narrow", sans-serif;
}