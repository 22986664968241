.page-container{
    background: #cedeec;
    /* width: 100%; */
    height: 100%;
    padding-left: 15px;
    padding-top: 15px;
    /* padding-bottom: 75px; */
  }

.page-container-horizontal-line{
    height: 1px;
    background-color: black;
    width: 80%;
    margin: 20px auto;
}

.page-container-left{
  width:20%;
}
.page-container-right{
  width:80%;
}
.page-container-center{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 75px;

}
  /* header */
.page-container h2{
  color:#000071;
  font-size:30px;
  font-family: Bookman, "URW Bookman L", serif;
  text-align: center;
}

/* paragraphs */
.page-container p{
  color:#000071;
  font-size:20px;
  font-family:"Trebuchet MS", sans-serif;
  text-align: left;
  padding-right:20px;
  padding-left:20px;
}
.page-container h3{
  color:#000071;
  font-size:20px;
  font-family: "Trebuchet MS", sans-serif;
  text-align: left;
}

.page-container textarea{
  width: 80%;
  resize: vertical;
  overflow: auto;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 10px;
  margin-left: 25px;
}

.page-container input{
  padding: 3px;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 5px;
}

.page-container button{
  background-color: #3e4e9b;
  color: white;
  padding: 3px;
  margin: 3px;
  font-size: 16px;
  line-height: 1.5;
  font-family: "Arial Narrow", sans-serif;
}

.page-container label{
  color:#000071;
  font-size:20px;
  font-family:"Trebuchet MS", sans-serif;
  text-align: left;
  padding-right:20px;
  padding-left:20px;
}