.dark-color-container{
  background: #3e4e9b;
  color: white;
  padding:0px;
  margin:0px;
}

/* header */
.dark-color-container h1{
    text-align: center;
    font-family:"Lucida Handwriting","Brush Script MT", cursive;
    font-size: 35px;
    top:0;
    padding: 20px;
  }
  
  /* footer */
.dark-color-container-footer{
    background: #3e4e9b;
    color: white;
    /* TODO check use of below */
    padding: 20px;
    position: fixed;
    bottom:0;
    width:100%;
    height:30px;
    flex-shrink:0;
  }

  .dark-color-container-footer h2{
    text-align: center;
    font-family:"Lucida Console", Monaco, monospace;
    font-size: 14px;
  }

  .horizontal-line-header{
    height: 1px;
    background-color: white;
    width: 80%;
    /* padding-bottom: 10px; */
    margin: 0px auto;
}